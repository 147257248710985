import { QRCodeSVG } from "qrcode.react";
import { Fragment, useRef, useState } from "react";
import ModalTableProdutos from "../../modal/ModalTableProdutos";

import "./BodyRow.scss";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateProdutosListChecked } from "../../../redux/slicers/user";
import ModalAtualizarDimensoes from "../../modal/ModalAtualizarDimensoes";
import {
	getData,
	updateTable,
	updateTableNew,
} from "../../../services/Servicos";
import ModalImprimirQRCode from "../../modal/ModalImprimirQRCode";
import ModalError from "../../modal/ModalError";
import ModalVisualizarPDFComputer from "../../modal/ModalVisualizarPDFComputer";
import ModalEditarRowProduto from "../../modal/ModalEditarRowProduto";
import ModalErrorPretty from "../../modal/ModalErrorPretty";
import { getErrorMessage } from "../../../services/helpers";

export default function BodyRowProduto({
	headList,
	bodyList,
	index,
	caminhao,
	setLoadingFinalizarParcial = () => {},
	setError = () => {},
	setSeparador = () => {},
	separador,
	cargaSelecionada,
	tableLength,
	updateArrayProdutos = () => {},
}) {
	const userEmail = useSelector((state) => state.userSlice.email);
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;
	const refRow = useRef(null);
	const refRowCheck = useRef(null);
	const refEditar = useRef(null);
	const dispatch = useDispatch();

	const [produtos, setProdutos] = useState({
		flag: "",
		ID_EMPRESA: "",
		ID_FILIAL: "",
		ID_PEDIDO: "",
	});
	const [editarDimensoes, setEditarDimensoes] = useState({
		flag: false,
		dimensao: "",
		produto: "",
		descricao: "",
		parametro: "",
		qr_sku: "",
		msg_button: "",
		currentValue: 0,
	});
	const [imprimir, setImprimir] = useState({
		flag: false,
		title: "",
		qr_sku: "",
		msg_obj: {},
	});

	const setHandlerClicked = async (e, bodyList) => {
		const qr_sku = e.target.name.trim();
		const checked = e.target.checked;
		const obj = {
			conferido: checked,
			data_conferencia: new Date().toISOString(),
			user_conferencia: userEmail,
			setor_conferencia: user.local_separacao,
			comprimento: String(bodyList.comprimento).replace(",", "."),
			altura: String(bodyList.altura).replace(",", "."),
			largura: String(bodyList.largura).replace(",", "."),
			peso: String(bodyList.peso).replace(",", "."),
			volume: String(bodyList.volume).replace(",", "."),
		};
		try {
			const result = await updateTable(
				"carga/separador/produto/conferido",
				bodyList.id,
				obj
			);

			dispatch(updateProdutosListChecked({ qr_sku, ...obj }));
		} catch (error) {}
	};

	const onClickEditar = async (
		e,
		parametro,
		qr_sku,
		produto,
		currentValue,
		descricao
	) => {
		if (
			cargaSelecionada.tipo_transporte == "PRÓPRIO" &&
			cargaSelecionada.tipo_carga == "PADRÃO" &&
			!cargaSelecionada.caminhao
		) {
			setError({
				title: "Aviso",
				msg: "Não é Possível editar dimensões para carga PADRÃO sem CAMINHÃO",
				flag: true,
			});

			return;
		}
		if (separador.verificado && !separador.isSeparador) {
			setLoadingFinalizarParcial({
				flag: false,
				msg: "Error",
				style: "danger",
			});

			setTimeout(() => {
				setLoadingFinalizarParcial({
					flag: false,
					msg: "Finalizar Parcial",
					style: "primary",
				});
			}, 1500);

			setError({
				title: "Aviso",
				msg: "Separação ja iniciada por outro usuário. Prossiga com outra Tarefa.",
				flag: true,
			});

			return;
		} else if (!separador.verificado) {
			setLoadingFinalizarParcial({
				flag: true,
				msg: "Verificando",
			});
			const teste = await getData(
				"cargas/produtos/separador/verificar",
				bodyList.cargas_id,
				{ id_filial: bodyList.id_filial }
			);

			if (
				teste.data?.exists &
				(user.email?.toLowerCase() !== teste.data.separador?.toLowerCase()) &
				(user?.local_separacao?.toLowerCase() ===
					teste.data?.setor?.toLowerCase())
			) {
				setLoadingFinalizarParcial({
					flag: false,
					msg: "Error",
					style: "danger",
				});

				setTimeout(() => {
					setLoadingFinalizarParcial({
						flag: false,
						msg: "Finalizar Parcial",
						style: "primary",
					});
				}, 1500);

				setError({
					title: "Aviso",
					msg: "Separação ja iniciada por outro usuário. Prossiga com outra Tarefa.",
					flag: true,
				});

				setSeparador({ verificado: true, isSeparador: false });
				return;
			}
			setSeparador({ verificado: true, isSeparador: true });
		}
		setLoadingFinalizarParcial({
			flag: false,
			msg: "Finalizar Parcial",
		});
		let maxValue = caminhao[parametro];

		if (parametro === "peso") {
			maxValue = caminhao.capacidade ?? 0;
		}
		setEditarDimensoes((last) => ({
			...last,
			flag: true,
			dimensao: parametro,
			produto,
			qr_sku,
			currentValue,
			maxValue,
			descricao,
			cargas_id: bodyList.cargas_id,
		}));
	};

	const onClickPrint = (e) => {
		setImprimir({
			flag: true,
			msg_obj: bodyList,
			title: "Imprimir QRCode do Produto",
		});
	};


	const [verPDF, setVerPDF] = useState({
		show: false,
		PDF: "",
		title: "",
	});


	const onClickTableRow = (e) => {
		// e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = index;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = index;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}
	};

	const [editarRow, setEditarRow] = useState({show: false})

	const fnRetornoEditRow = async (bodyEdit, params) => {
		try {
			await updateTableNew(`produtos?idProduto=${params.idProduto}&idPedido=${params.idPedido}&idCarga=${params.idCarga}&idFilial=${params.idFilial}`, bodyEdit);
			updateArrayProdutos()
		} catch (error) {
			setError({
				title: "Aviso",
				msg: getErrorMessage(error),
				flag: true,
			});
		}
	};

	const onClickEdit = () => {
		setEditarRow({
			show: true,
			row: bodyList,
		})
	}

	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={onClickTableRow}>
					{headList.map((item, _index) => {
						// if(index > 19 ){
						//     return false
						// }

						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item.toLowerCase() === "admin") {
							return (
								<td key={_index}>
									<div className="admin" style={{ alignItems: "baseline" }}>
										<span className="imprimir">
											<i
												className="bi bi-printer"
												title="Imprimir Romaneio"
												onClick={onClickPrint}
												ref={refEditar}
											></i>
										</span>
										{
											user.email === process.env.REACT_APP_EMAIL_SUPORTE 
												? <span className="imprimir">
													<i
														className="bi bi-pencil"
														title="Imprimir Romaneio"
														onClick={onClickEdit}
														ref={refEditar}
													></i>
												</span>
												:""

										}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "qtde") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.qtde).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil"
													title="Editar Quantidade"
													onClick={(e) => {
														onClickEditar(
															e,
															"qtde",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.qtde,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "comprimento") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.comprimento).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil"
													title="Editar Comprimento"
													onClick={(e) => {
														onClickEditar(
															e,
															"comprimento",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.comprimento,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item === "id_filial") {
							return (
								<td key={_index}>
									{filiaisList.find(
										(item) => item.idFilial == bodyList.id_filial
									)?.identificacaoInterna ?? "NÃO INFORMADO"}
								</td>
							);
						} else if (item === "descricao") {
							return (
								<td key={_index}>
									<textarea
										rows={3}
										cols={30}
										value={bodyList.descricao}
									></textarea>
								</td>
							);
						} else if (item.toLowerCase() === "altura") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.altura).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil"
													title="Editar Altura"
													onClick={(e) => {
														onClickEditar(
															e,
															"altura",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.altura,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "largura") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{String(bodyList.largura).replace(".", ",")}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil bi-circle-animation"
													title="Editar Largura"
													onClick={(e) => {
														onClickEditar(
															e,
															"largura",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.largura,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "peso") {
							return (
								<td key={_index}>
									<div className="d-flex justify-content-between">
										<span
											className="verificar"
											style={{ fontSize: "inherit", color: "inherit" }}
										>
											{Number(bodyList[item]).toLocaleString()}
										</span>
										{user.tipo.toLowerCase() !== "separador" ? (
											""
										) : (
											<span
												className="verificar"
												style={{
													fontSize: "inherit",
													color: "inherit",
													cursor: "pointer",
												}}
											>
												<i
													className="bi bi-pencil bi-circle-animation"
													title="Editar Peso"
													onClick={(e) => {
														onClickEditar(
															e,
															"peso",
															bodyList.qr_sku,
															bodyList.codigo,
															bodyList.peso,
															bodyList.descricao
														);
													}}
												></i>
											</span>
										)}
									</div>
								</td>
							);
						} else if (item.toLowerCase() === "volume") {
							return (
								<td key={_index}>
									{String(bodyList.volume).replace(".", ",")}
								</td>
							);
						} else if (item === "conferido") {
							return (
								<td
									key={_index}
									className={item}
									style={{ minWidth: "50px", textAlign: "center" }}
								>
									{user.tipo.toLowerCase() !== "separador" ? (
										<Form.Check
											ref={refRowCheck}
											defaultChecked={bodyList?.conferido ?? false}
											disabled
										/>
									) : (
										<Form.Check
											ref={refRowCheck}
											defaultChecked={bodyList?.conferido ?? false}
											onChange={(e) => setHandlerClicked(e, bodyList)}
											name={bodyList.qr_sku}
											disabled={
												cargaSelecionada?.regiao_entrega == "LOCAL"
													? !bodyList.qtde || !bodyList.peso
														? true
														: false
													: !bodyList.altura ||
													  !bodyList.comprimento ||
													  !bodyList.largura ||
													  !bodyList.peso
											}
										/>
									)}
									{/* name ={`${bodyList.numero_pedido} - ${bodyList.codigo}`}/> */}
								</td>
							);
						} else if (item === "descricao") {
							return (
								<td
									key={_index}
									className={item}
									style={{ whiteSpace: "nowrap" }}
								>
									{" "}
									{bodyList[item]}
								</td>
							);
						} else if (item === "qr_code_id") {
							return (
								<td key={_index} className="rowDataWidth">
									<QRCodeSVG value={"{\n" + bodyList.qr_code_id + "}"} />
								</td>
							);
						} else if (
							item === "carregado" ||
							item === "descarregado" ||
							item === "planejado"
						) {
							//
							return (
								<td
									key={_index}
									style={{ minWidth: "50px", textAlign: "center" }}
								>
									<Form.Check
										ref={refRowCheck}
										defaultChecked={bodyList[item] ?? false}
										disabled
									/>
								</td>
							);
						}
						// else if (item === 'planejado') {
						//     //
						//     return <td key={_index} className='rowDataWidth'>{ bodyList.planejado ? 'SIM' : 'NÃO'  }</td>

						// }
						// else if (item === 'descarregado') {
						//     //
						//     return <td key={_index} className='rowDataWidth'>{ bodyList.descarregado ? 'SIM' : 'NÃO'  }</td>

						// }
						else if (
							item === "data_carregamento" ||
							item === "data_descarregamento" ||
							item === "data_conferencia" ||
							item === "data_cancelamento" ||
							item === "DTEMISSAO"
						) {
							return bodyList[item] ? (
								<td key={_index}>
									{" "}
									{new Date(bodyList[item]).toLocaleString()}
								</td>
							) : (
								<td key={_index}></td>
							);
						} else if (item === "numero_embalagem") {
							return (
								<td key={_index}>
									{" "}
									{bodyList.embalado
										? String(bodyList.numero_embalagem).padStart(7, 0)
										: "NÃO"}
								</td>
							);
							// return bodyList[item] ? <td key={_index}> { String(bodyList.numero_embalagem).padStart(7, 0)}</td> : <td key={_index}></td>
						} else if (item === "embalado") {
							return <td key={_index}> {bodyList.embalado ? "SIM" : "NÃO"}</td>;
						}
						return (
							<td key={_index} className={item} style={{ minWidth: "100px" }}>
								{" "}
								{bodyList[item] ?? "NÃO"}
							</td>
						);
					})}
				</tr>
			}
			{produtos.flag ? (
				<ModalTableProdutos
					show={produtos.flag}
					setShow={setProdutos}
					ID_EMPRESA={produtos.ID_EMPRESA}
					ID_FILIAL={produtos.ID_FILIAL}
					ID_PEDIDO={produtos.ID_PEDIDO}
				/>
			) : (
				""
			)}
			{editarDimensoes.flag ? (
				<ModalAtualizarDimensoes
					show={editarDimensoes.flag}
					setShow={setEditarDimensoes}
					{...editarDimensoes}
				/>
			) : (
				""
			)}

			{imprimir.flag ? (
				<ModalImprimirQRCode
					title={imprimir.title}
					show={imprimir.flag}
					setShow={setImprimir}
					msg_obj={imprimir.msg_obj}
				/>
			) : (
				""
			)}
			{verPDF.show ? (
				<ModalVisualizarPDFComputer
					title={verPDF.title}
					pdf={verPDF.PDF}
					setShow={setVerPDF}
					show={verPDF.show}
				/>
			) : (
				""
			)}
			
			{editarRow.show ? (
				<ModalEditarRowProduto
					title={"Editar Dados do Produto"}
					setShow={setEditarRow}
					{...editarRow}
					fnRetorno={fnRetornoEditRow}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
