import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	updateAbastecimentosList,
	updateCargasList,
	updateFreteirosList,
} from "../../redux/slicers/user";
import {
	cpf_mask,
	createFreteiro,
	getAllData,
	numerosDecimal,
	updateTable,
	verificaTipoUsuario,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalError from "./ModalError";
import { telefoneMask } from "../../services/helpers";

export default function ModalCriarFreteiro(props) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userSlice);
	const filiaisList = user.filiaisList.data;


	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Salvar",
		style: "primary",
	});

	const [body, setBody] = useState({
		responsavel: user.name,
		id_filial: user.tipo == "empresa" ? 2 : user.id_filial,
	});
	const [error, setError] = useState({
		flag: false,
	});

	const handlerSimpleClose = () =>
		props.setShow((last) => ({ ...last, flag: false }));

	const submitDataEdit = () => {
		if (!body.placa) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Freteiro",
					motivo: "Não é possível criar caminhão de Frete sem Placa.",
				},
			});
			return;
		}
		if (!body.motorista_cpf) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Freteiro",
					motivo: "Não é possível criar Freteiro sem o CPF do Motorista.",
				},
			});
			return;
		}
		if (!body.motorista_nome) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Freteiro",
					motivo: "Não é possível criar Freteiro sem o NOME do Motorista.",
				},
			});
			return;
		}
		if (!body.transportadora) {
			setError({
				flag: true,
				title: "Erro",
				msg: {
					erro: "Lançamento de Freteiro",
					motivo: "Não é possível criar Freteiro sem o NOME da TRANSPORTADORA.",
				},
			});
			return;
		}

		setLoading({
			isLoading: true,
			msg: "Salvando",
			style: "warning",
		});
		let fBody = {};

		Object.keys(body).forEach((e) => {
			if (e == "capacidade" || e == "tara" || e == "pbt") {
				fBody[e] = body[e].replace(",", ".");
				return;
			}
			fBody[e] = body[e];
		});

		createFreteiro(fBody, user.tipo)
			.then((response) => {
				setLoading({
					isLoading: false,
					msg: "Enviado",
					style: "success",
				});
				setTimeout(() => {
					updateFreteiroListArray();
				}, 700);
			})
			.catch((error) => {
				if (error.response?.data?.errors) {
					setError({
						flag: true,
						title: "Atenção",
						msg: {
							title: "Erro no Lancamento",
							motivo: "Freteiro já Cadastrado Anteriormente",
						},
					});
				} else {
					setError({
						flag: true,
						title: "Atenção",
						msg: error,
					});
				}
				setLoading({
					isLoading: false,
					msg: "Error",
					style: "danger",
				});

				setTimeout(() => {
					setLoading({
						isLoading: false,
						msg: "Enviar",
						style: "primary",
					});
				}, 1000);
			});
	};

	const onChangeInput = (e) => {
		if (
			e.target.name === "capacidade" ||
			e.target.name === "tara" ||
			e.target.name === "pbt"
		) {
			setBody((last) => ({
				...last,
				[e.target.name]: numerosDecimal(e.target.value.replace(/\D/g, "")),
			}));
			return;
		}
		if (e.target.name === "motorista_cpf") {
			setBody((last) => ({
				...last,
				[e.target.name]: cpf_mask(e.target.value),
			}));
			return;
		}
		if (e.target.name === "motorista_telefone") {
			setBody((last) => ({
				...last,
				[e.target.name]: telefoneMask(e.target.value),
			}));
			return;
		}
		if (e.target.name === "email") {
			if(e.target.value.includes('.frete@fermat.com')){
				setValidaEmail(false)
			}else{
				setValidaEmail(true)
			}
			setBody((last) => ({
				...last,
				[e.target.name]: e.target.value,
			}));
			return;
		}
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value.toUpperCase(),
		}));
	};

	const updateFreteiroListArray = () => {
		getAllData("caminhoes/freteiro", {
			id_filial: user.tipo == "empresa" ? null : user.id_filial,
		})
			.then((response) => {
				dispatch(updateFreteirosList(response.data));
				handlerSimpleClose();
			})
			.catch((err) => {
				setError({
					flag: true,
					msg: {
						title: "Erro na atualização da lista de abastecimentos",
						...err,
					},
					title: "Aviso",
				});
			})
			.finally(() => {
				setLoading({
					isLoading: false,
					msg: "Enviar",
					style: "primary",
				});
			});
	};

	const [validaEmail, setValidaEmail] = useState(true)
	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							submitDataEdit();
						}}
					>
						
						<h4>Dados cadastrais</h4>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Tipo de Caminhão:</Form.Label>
							</Col>
							<Col>
								<Form.Select type="text" name="tipo" onChange={onChangeInput}>
									<option value=""></option>
									<option value="Toco">Toco</option>
									<option value="Truck">Truck</option>
									<option value="BiTruck">BiTruck</option>
									<option value="Carreta">Carreta</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Placa:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="placa"
									onChange={onChangeInput}
									maxLength={8}
									value={body.placa}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Capacidade:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="capacidade"
									onChange={onChangeInput}
									value={body.capacidade}
									maxLength={5}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>PBT:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="pbt"
									onChange={onChangeInput}
									value={body.pbt}
									maxLength={5}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>TARA:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="tara"
									onChange={onChangeInput}
									value={body.tara}
									maxLength={5}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>CPF do Motorista:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="motorista_cpf"
									onChange={onChangeInput}
									value={body.motorista_cpf}
									maxLength={14}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Nome do Motorista:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="motorista_nome"
									onChange={onChangeInput}
									value={body.motorista_nome}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Telefone do Motorista:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="motorista_telefone"
									onChange={onChangeInput}
									value={body.motorista_telefone}
									maxLength={15}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Transportadora:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="transportadora"
									onChange={onChangeInput}
									value={body.transportadora}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Propriedade:</Form.Label>
							</Col>
							<Col>
								<Form.Select
									onChange={onChangeInput}
									defaultValue={body.id_filial}
									disabled={user.tipo !== "empresa" || user.id_filial == 2}
								>
									<option value={0}></option>
									{filiaisList.map((item, index) => (
										<option key={index} value={item.idFilial}>
											{item.identificacaoInterna}
										</option>
									))}
								</Form.Select>
							</Col>
						</Row>
						<hr/>
						<h4>Acesso ao aplicativo</h4>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Email:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="email"
									name="email"
									placeholder="Email deve ter '.frete@fermat.com'"
									onChange={onChangeInput}
									value={body.email}
								/>
								{validaEmail ? <div className="text-danger">O email de acesso deve possuir <i style={{fontSize: 'inherit', fontWeight: 'bold'}}>.frete@fermat.com</i></div>: ''}
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Senha:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="senha"
									placeholder="Digite a senha do motorista..."
									onChange={onChangeInput}
									value={body.senha}
								/>
							</Col>
						</Row>
						<button type="submit" hidden></button>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }}>
						<Col>
							<Button
								type="submit"
								variant={loading.style}
								onClick={submitDataEdit}
								disabled={loading.style == "primary" ? false : true}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error ? (
					<ModalError
						show={error.flag}
						setShow={setError}
						title={error.title}
						msg={error.msg}
					/>
				) : (
					""
				)}
			</Modal>
		</>
	);
}
