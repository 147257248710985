import { Fragment, createRef, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Table, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
	apenasNumeros,
	createDespesa,
	getAllData,
	getData,
	getTableColumns,
	numeroDecimais,
	numerosDecimal,
	updateTable,
	updateTableNew,
	valorMoeda,
} from "../../services/Servicos";
import Loading from "../loading/Loading";

import "./Modal.scss";
import ModalError from "./ModalError";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../services/helpers";
// import BodyRow from "../table/body/BodyRow"
import ScrollbarLeft from "../scrollbars/ScrollbarLeft";
import HeadTable from "../table/head/HeadRow";
import ModalErrorPretty from "./ModalErrorPretty";

const formRef = createRef(null);

export default function ModalFechamentoFinanceiro(props) {
	const tableRef = useRef();
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Lançar",
		style: "primary",
	});
	const [loadingData, setLoadingData] = useState(true);

	const [error, setError] = useState({
		show: false,
		msg: "",
	});
	const [atualizar, setAtualizar] = useState(false);

	const [body, setBody] = useState({
		tipo_lancamento:
			props.status === 13 ? "Fechamento Financeiro" : "Adicional de Crédito",
		titulo: "",
		caminhao: props.caminhao_placa,
		cargas_id: props.cargas_id,
		caminhoneiro: props.cpf_caminhoneiro,
		tipo: null,
		valor: null,
	});

	const [despesasViagem, setDespesasViagem] = useState([]);

	const user = useSelector((state) => state.userSlice);

	const onClickAdicionarDespesas = (e) => {
		if (!body.data_depesa)
			return setError({
				show: true,
				message: "Data da despesa não informado!",
			});
		if (!body.valor.length)
			return setError({
				show: true,
				message: "Valor da despesa não informado!",
			});
		if (!body.tipo.length)
			return setError({
				show: true,
				message: "Tipo da despesa não informado!",
			});

		setDespesasViagem((last) => [...last, body]);
		tableRef.current.reset();
		setBody((last) => ({
			...last,
			valor: "",
			tipo: "",
			titulo: "",
			forma_pagamento: "",
		}));
	};

	const resetInfo = () => {
		setBody((last) => ({
			...last,
			valor: "",
			tipo: "",
			titulo: "",
			forma_pagamento: "",
			tipo_alimentacao: "",
		}));
		formRef.current.tipo.value = "";
		formRef.current.forma_pagamento.value = "";
		formRef.current.data_despesa.value = "";

		if (formRef.current.tipo_alimentacao) {
			formRef.current.tipo_alimentacao.value = "";
		}

		setLoading({
			isLoading: false,
			msg: "Lançar",
			style: "primary",
		});
	};

	const submitDataEdit = async () => {
		let finalBody = null;

		if (!body.valor || !body.tipo_lancamento)
			return setError({
				show: true,
				title: "Aviso!",
				message:
					"Falha ao enviar Dados: Campos Valor e Tipo de lançamento não informados",
			});
		if (!body.forma_pagamento)
			return setError({
				show: true,
				title: "Aviso!",
				message: "Falha ao enviar Dados: Forma de pagamento não especificado",
			});
		if (!body.data_despesa)
			return setError({
				show: true,
				title: "Aviso!",
				message: "Falha ao enviar Dados: Data da despesa não especificado",
			});

		setLoading({ isLoading: true, msg: "Atualizando Carga", style: "warning" });

		if (body.tipo == "devolucao") {
			finalBody = {
				...body,
				valor: -String(body.valor).replace(".", "").replace(",", "."),
			};
		} else {
			finalBody = {
				...body,
				valor: String(body.valor).replace(".", "").replace(",", "."),
			};
		}
		try {
			await createDespesa(finalBody);
			setLoading({
				isLoading: false,
				msg: "Despesa Lançada",
				style: "success",
			});
			setTimeout(() => {
				props.setAtualizarDespesa({
					flag: true,
					carga: props.id,
				});
				setAtualizar((last) => !last);
				resetInfo();
			}, 700);
		} catch (error) {
			setError({
				show: true,
				message: error.message ?? "Erro no Banco de Dados",
			});
		}
	};

	const onChangeInput = (e) => {
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	const editValue = (e) => {
		// const value = Number(valor1).toLocaleString('fullwide', {maximumFractionDigits:2, style:'number', currency:'BRL', useGrouping:true})
		setBody((last) => ({
			...last,
			valor: numeroDecimais(apenasNumeros(e.target.value)),
		}));
	};

	const handlerSimpleClose = () => props.setShow((last) => ({ flag: false }));

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.title} {props.cargas_id}{" "}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col sm="12" md="6">
							<div className="card-header d-flex align-items-center">
								<h5
									style={{
										display: "flex",
										alignItems: "center",
										marginRight: "10px",
									}}
								>
									Despesas
								</h5>
								{loadingData ? <Spinner size="sm" animation="border" /> : ""}
							</div>
							<TableLancamentos
								cargas_id={props.cargas_id}
								setLoading={setLoadingData}
								setAtualizarDespesa={props.setAtualizarDespesa}
								atualizar={atualizar}
								setAtualizar={setAtualizar}
							/>
						</Col>
						<Col sm="12" md="6">
							<div className="card-header">
								<h5 style={{ display: "flex", alignItems: "center" }}>
									Lançamento
								</h5>
							</div>
							<Form
								ref={formRef}
								onKeyDown={(e) => {
									if (e.key == "Enter") {
										submitDataEdit(e);
									}
								}}
							>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Tipo de Lançamento:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											value={
												props.status === 13
													? "Fechamento Financeiro"
													: "Adicional de Crédito"
											}
											name="tipo_lancamento"
											disabled
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Titulo da Despesa:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											onChange={onChangeInput}
											name="titulo"
											value={body.titulo}
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Caminhao:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											disabled
											defaultValue={props.caminhao_placa}
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Carga:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											disabled
											defaultValue={props.cargas_id}
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Condutor:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											disabled
											defaultValue={props.nome_caminhoneiro}
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Tipo:</Form.Label>
									</Col>
									<Col>
										<Form.Select
											type="text"
											name="tipo"
											onChange={onChangeInput}
										>
											<option value=""></option>
											<option value="pedagio">Pedágio</option>
											<option value="refeicao">Refeicao</option>
											<option value="manutencao">Manutenção do Caminhao</option>
											<option value="devolucao">Devolução</option>
											<option value="outros">Outros</option>
										</Form.Select>
									</Col>
								</Row>
								{body.tipo === "refeicao" ? (
									<Row className="mb-2">
										<Col sm="4">
											<Form.Label>Descrição do Tipo:</Form.Label>
										</Col>
										<Col>
											<Form.Select
												type="text"
												name="tipo_alimentacao"
												onChange={onChangeInput}
											>
												<option value=""></option>
												<option value="café">Café da Manhã</option>
												<option value="almoço">Almoço</option>
												<option value="jantar">Jantar</option>
												<option value="outros">Outros</option>
											</Form.Select>
										</Col>
									</Row>
								) : (
									""
								)}
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Valor:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											onChange={editValue}
											value={body.valor}
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Forma de Pagamento</Form.Label>
									</Col>
									<Col>
										<Form.Select
											type="text"
											name="forma_pagamento"
											onChange={onChangeInput}
										>
											<option value=""></option>
											<option value="cartão">Cartão</option>
											<option value="dinheiro">Dinheiro</option>
											<option value="dinheiro proprio">Dinheiro Próprio</option>
										</Form.Select>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col sm="4">
										<Form.Label>Data:</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="date"
											name="data_despesa"
											onChange={onChangeInput}
										/>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }} className="justify-content-end">
						<Col sm="4">
							<Button
								variant={loading.style}
								onClick={submitDataEdit}
								disabled={loading.style == "primary" ? false : true}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
							</Button>
						</Col>
						{props.status === 11 ? (
							<Col>
								<Button variant={"info"} onClick={onClickAdicionarDespesas}>
									Adicionar
								</Button>
							</Col>
						) : (
							""
						)}
						<Col sm="4">
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
			</Modal>
		</>
	);
}

function TableLancamentos(props) {
	const user = useSelector((state) => state.userSlice);
	const [despesas, setDespesas] = useState([]);
	const [headList, setHeadList] = useState([
		"index",
		"admin",
		"id",
		"tipo_lancamento",
		"tipo",
		"tipo_alimentacao",
		"data_despesa",
		"valor",
		"forma_pagamento",
		"titulo",
	]);

	const [indexList, setIndexList] = useState(10);
	const [filters, setFilters] = useState([]);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filtersNew, setFiltersNew] = useState({});
	const [atualizarDados, setAtualizarDados] = useState(false);

	const tableRef = useRef(null);
	const tbodyRef = useRef(null);

	const selectData = async () => {
		const url =
			user.tipo == "empresa"
				? "despesas/empresa/carga"
				: "despesas/gestao/carga";
		const dados = await getData(url, props.cargas_id);
		setDespesas(dados.data.reverse());
		props.setLoading(false);
		setAtualizarDados(false);
		props.setAtualizarDespesa({
			flag: true,
			cargas_id: props.cargas_id,
		});
	};

	useEffect(() => {
		props.setLoading(true);
		selectData();
	}, [atualizarDados, props.atualizar]);

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);

		setFilteredTable(arrayF.reverse());
	}, [filters]);

	return (
		<div className=" responsive-table" ref={tableRef}>
			<ScrollbarLeft tableRef={tableRef} coluna2={2} maxima_distancia={40} />
			<Table hover size="sm">
				<thead style={{ zIndex: 49 }}>
					<HeadTable
						rota="despesas"
						arrayValues={despesas}
						headerList={headList}
						setArrayFilters={setFiltersNew}
						arrayFilters={filtersNew}
						setFiltersOld={setFilters}
						filtersOld={filters}
					/>
				</thead>
				<tbody ref={tbodyRef}>
					{filteredTable
						? filteredTable.map((item, index) => {
								return (
									<BodyRow
										key={index}
										bodyList={item}
										headList={headList}
										index={index}
										tableLength={indexList}
										setAtualizarDados={setAtualizarDados}
									/>
								);
						  })
						: despesas.map((item, index) => {
								return (
									<BodyRow
										key={index}
										bodyList={item}
										headList={headList}
										index={index}
										tableLength={indexList}
										setAtualizarDados={setAtualizarDados}
									/>
								);
						  })}
				</tbody>
			</Table>
			{/* CASO NÃO HAJA MAIS VENDAS, REMOVER LOADING  */}
		</div>
	);
}

function BodyRow({ headList, bodyList, index, setAtualizarDados }) {
	const refRow = useRef(null);
	const [modalUpdate, setModalUpdate] = useState({
		flag: false,
	});
	const [modalCancel, setModalCancel] = useState({
		flag: false,
	});

	const onClickEditar = () => {
		setModalUpdate({
			flag: true,
			bodyList: bodyList,
		});
	};

	const onClickExcluir = () => {
		setModalCancel({
			flag: true,
			bodyList: bodyList,
		});
	};

	return (
		<Fragment>
			{
				<tr ref={refRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="excluir">
											<i
												className="bi bi-x-lg"
												title="Excluir"
												onClick={onClickExcluir}
											></i>
										</span>
										<span className="editar">
											<i
												className="bi bi-pencil"
												title="Editar"
												onClick={onClickEditar}
											></i>
										</span>
									</div>
								</td>
							);
						} else if (item === "id") {
							/* primary key can not be modified */

							return <td key={_index}>{bodyList[item]}</td>;
						} else if (item === "valor") {
							/* primary key can not be modified */

							return <td key={_index}>{valorMoeda(bodyList[item])}</td>;
						} else if (item.includes("data")) {
							/* primary key can not be modified */

							return (
								<td key={_index}>
									{bodyList[item]
										? new Date(bodyList[item]).toLocaleDateString("pt-br", {
												timeZone: "UTC",
										  })
										: "N/A"}
								</td>
							);
						}
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{modalUpdate.flag ? (
				<UpdateDespesa
					bodyList={bodyList}
					show={modalUpdate.flag}
					setShow={setModalUpdate}
					setAtualizarDados={setAtualizarDados}
				/>
			) : (
				""
			)}
			{modalCancel.flag ? (
				<CancelDespesa
					bodyList={bodyList}
					show={modalCancel.flag}
					setShow={setModalCancel}
					setAtualizarDados={setAtualizarDados}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}

function UpdateDespesa(props) {
	const formRef = useRef();

	const user = useSelector((state) => state.userSlice);
	const [body, setBody] = useState({});
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Atualizar",
		style: "primary",
	});

	const [error, setError] = useState({
		show: false,
		msg: "",
	});

	const submitDataEdit = async () => {
		const url =
			user.tipo.toLowerCase() == "empresa"
				? "despesas/empresa/atualizar"
				: "despesas/gestao/atualizar";
		setLoading({ isLoading: true, msg: "Atualizando", style: "warning" });

		await updateTableNew(`${url}/${props.bodyList.id}`, {
			...body,
			valor: body.valor.replace(".", "").replace(",", "."),
		})
			.then((resp) => {
				setLoading({ isLoading: false, msg: "Atualizado", style: "success" });
				setTimeout(() => {
					props.setAtualizarDados();
					handlerSimpleClose();
				}, 500);
			})
			.catch((err) => {});
	};

	const onChangeInput = (e) => {
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	const editValue = (e) => {
		// const value = Number(valor1).toLocaleString('fullwide', {maximumFractionDigits:2, style:'number', currency:'BRL', useGrouping:true})
		setBody((last) => ({
			...last,
			valor: numerosDecimal(apenasNumeros(e.target.value)),
		}));
	};

	const handlerSimpleClose = () => props.setShow((last) => ({ flag: false }));

	useEffect(() => {
		const date = new Date(props.bodyList.data_despesa).toLocaleDateString(
			"pt-br",
			{ timeZone: "UTC" }
		);

		const [dia, mes, ano] = date.split("/");

		setBody((last) => ({
			...last,
			valor: numerosDecimal(
				apenasNumeros(String((props.bodyList.valor * 100).toFixed(0)))
			),
			data_despesa: props.bodyList.data_despesa ? `${ano}-${mes}-${dia}` : null,
		}));
	}, []);

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title> Atualizar Despesa N°{props.bodyList.id}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form ref={formRef}>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Tipo de Lançamento:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									value={props.bodyList.tipo_lancamento}
									name="tipo_lancamento"
									disabled
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Titulo da Despesa:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									onChange={onChangeInput}
									name="titulo"
									defaultValue={props.bodyList.titulo}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Caminhao:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									disabled
									defaultValue={props.bodyList.caminhao}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Carga:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									disabled
									defaultValue={props.bodyList.cargas_id}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Condutor:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									disabled
									defaultValue={props.bodyList.caminhoneiro}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Tipo:</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="tipo"
									onChange={onChangeInput}
									defaultValue={props.bodyList.tipo.toLowerCase()}
								>
									<option value=""></option>
									<option value="pedagio">Pedágio</option>
									<option value="refeicao">Refeicao</option>
									<option value="manutencao">Manutenção do Caminhao</option>
									<option value="devolucao">Devolução</option>
									<option value="outros">Outros</option>
								</Form.Select>
							</Col>
						</Row>
						{props.bodyList.tipo === "refeicao" || body.tipo == "refeicao" ? (
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Descrição do Tipo:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="tipo_alimentacao"
										onChange={onChangeInput}
										defaultValue={props.bodyList.tipo_alimentacao}
									>
										<option value=""></option>
										<option value="café">Café da Manhã</option>
										<option value="almoço">Almoço</option>
										<option value="jantar">Jantar</option>
										<option value="outros">Outros</option>
									</Form.Select>
								</Col>
							</Row>
						) : (
							""
						)}
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Valor:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="valor"
									onChange={editValue}
									value={body.valor}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Forma de Pagamento</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="forma_pagamento"
									onChange={onChangeInput}
									defaultValue={props.bodyList.forma_pagamento}
								>
									<option value=""></option>
									<option value="cartão">Cartão</option>
									<option value="dinheiro">Dinheiro</option>
									<option value="dinheiro proprio">Dinheiro Próprio</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Data:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="date"
									name="data_despesa"
									onChange={onChangeInput}
									value={body.data_despesa}
								/>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }} className="justify-content-end">
						<Col>
							<Button
								variant={loading.style}
								onClick={submitDataEdit}
								disabled={loading.style == "primary" ? false : true}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
			</Modal>
		</>
	);
}

function CancelDespesa(props) {
	const user = useSelector((state) => state.userSlice);
	const formRef = useRef();
	const [body, setBody] = useState({});
	const [loading, setLoading] = useState({
		isLoading: false,
		msg: "Excluir",
		style: "danger",
	});

	const [error, setError] = useState({
		show: false,
		msg: "",
	});

	const submitDataEdit = async () => {
		const url =
			user.tipo.toLowerCase() == "empresa"
				? "despesas/empresa/atualizar"
				: "despesas/gestao/atualizar";
		setLoading({ isLoading: true, msg: "Atualizando", style: "warning" });

		await updateTableNew(`${url}/${props.bodyList.id}`, {
			data_cancelamento: new Date().toISOString(),
			user_cancelamento: user.name,
		})
			.then((resp) => {
				setLoading({ isLoading: false, msg: "Atualizado", style: "success" });
				setTimeout(() => {
					props.setAtualizarDados();
					handlerSimpleClose();
				}, 500);
			})
			.catch((err) => {});
	};

	const onChangeInput = (e) => {
		setBody((last) => ({
			...last,
			[e.target.name]: e.target.value,
		}));
	};

	const editValue = (e) => {
		// const value = Number(valor1).toLocaleString('fullwide', {maximumFractionDigits:2, style:'number', currency:'BRL', useGrouping:true})
		setBody((last) => ({
			...last,
			valor: numerosDecimal(apenasNumeros(e.target.value)),
		}));
	};

	const handlerSimpleClose = () => props.setShow((last) => ({ flag: false }));

	useEffect(() => {
		const date = new Date(props.bodyList.data_despesa).toLocaleDateString(
			"pt-br",
			{ timeZone: "UTC" }
		);

		const [dia, mes, ano] = date.split("/");

		setBody((last) => ({
			...last,
			valor: numerosDecimal(
				apenasNumeros(String((props.bodyList.valor * 100).toFixed(0)))
			),
			data_despesa: props.bodyList.data_despesa ? `${ano}-${mes}-${dia}` : null,
		}));
	}, []);

	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title> Cancelar Despesa N°{props.bodyList.id}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form ref={formRef}>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Tipo de Lançamento:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									value={props.bodyList.tipo_lancamento}
									name="tipo_lancamento"
									disabled
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Titulo da Despesa:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									onChange={onChangeInput}
									name="titulo"
									defaultValue={props.bodyList.titulo}
									disabled
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Caminhao:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									disabled
									defaultValue={props.bodyList.caminhao}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Carga:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									disabled
									defaultValue={props.bodyList.cargas_id}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Condutor:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									disabled
									defaultValue={props.bodyList.caminhoneiro}
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Tipo:</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="tipo"
									onChange={onChangeInput}
									defaultValue={props.bodyList.tipo.toLowerCase()}
									disabled
								>
									<option value=""></option>
									<option value="pedagio">Pedágio</option>
									<option value="refeicao">Refeicao</option>
									<option value="manutencao">Manutenção do Caminhao</option>
									<option value="devolucao">Devolução</option>
									<option value="outros">Outros</option>
								</Form.Select>
							</Col>
						</Row>
						{props.bodyList.tipo === "refeicao" || body.tipo == "refeicao" ? (
							<Row className="mb-2">
								<Col sm="4">
									<Form.Label>Descrição do Tipo:</Form.Label>
								</Col>
								<Col>
									<Form.Select
										type="text"
										name="tipo_alimentacao"
										onChange={onChangeInput}
										defaultValue={props.bodyList.tipo_alimentacao}
										disabled
									>
										<option value=""></option>
										<option value="café">Café da Manhã</option>
										<option value="almoço">Almoço</option>
										<option value="jantar">Jantar</option>
										<option value="outros">Outros</option>
									</Form.Select>
								</Col>
							</Row>
						) : (
							""
						)}
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Valor:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="valor"
									onChange={editValue}
									value={body.valor}
									disabled
								/>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Forma de Pagamento</Form.Label>
							</Col>
							<Col>
								<Form.Select
									type="text"
									name="forma_pagamento"
									onChange={onChangeInput}
									defaultValue={props.bodyList.forma_pagamento}
									disabled
								>
									<option value=""></option>
									<option value="cartão">Cartão</option>
									<option value="dinheiro">Dinheiro</option>
									<option value="dinheiro proprio">Dinheiro Próprio</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Data:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="date"
									name="data_despesa"
									onChange={onChangeInput}
									value={body.data_despesa}
									disabled
								/>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Row style={{ width: "100%" }} className="justify-content-end">
						<Col>
							<Button
								variant={loading.style}
								onClick={submitDataEdit}
								disabled={loading.style == "danger" ? false : true}
							>
								{loading.isLoading ? (
									<Loading msg={loading.msg} />
								) : (
									loading.msg
								)}
								{loading.style == "success" ? (
									<i className="bi bi-check"></i>
								) : (
									""
								)}
							</Button>
						</Col>
						<Col>
							<Button variant="secondary" onClick={handlerSimpleClose}>
								Cancelar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
				{error.show ? <ModalErrorPretty setShow={setError} {...error} /> : ""}
			</Modal>
		</>
	);
}
