import { Fragment, createRef, useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	getAllData,
	getCaminhoes,
	getTableColumns,
	verificaTipoUsuario,
} from "../../../services/Servicos";
import ModalLancarAbastecimento from "../../modal/ModalLancarAbastecimento";
import {
	update,
	updateAbastecimentosList,
	updateCaminhoes,
	updateFreteirosList,
} from "../../../redux/slicers/user";
import BodyRowAbastecimento from "../../table/body/BodyRowAbastecimento";
import ScrollbarLeft from "../../scrollbars/ScrollbarLeft";
import HeadTable from "../../table/head/HeadRow";
import {
	filtroDinamicoArrays,
	removerDadosDuplicados,
} from "../../../services/helpers";
import ModalCriarFreteiro from "../../modal/ModalCriarFreteiro";
import BodyRowFrete from "../../table/body/BodyRowFrete";

const tableRef = createRef();
const tbodyRef = createRef();

export default function CaminhaoCadastroFreteiro() {
	return (
		<Fragment>
			<Card className="card-user">
				<ListarFreteiros />
			</Card>
		</Fragment>
	);
}

function ListarFreteiros() {
	const dispatch = useDispatch();

	const freteiro = useSelector((state) => state.userSlice.freteiroList);
	const user = useSelector((state) => state.userSlice);
	const [loading, setLoading] = useState(true);
	const [indexList, setIndexList] = useState(10);
	const [filteredTable, setFilteredTable] = useState(false);
	const [filters, setFilters] = useState([]);
	const [lancarModal, setLancarModal] = useState({
		flag: false,
	});
	const [editarAbastecimento, setEditarAbastecimento] = useState({
		flag: false,
	});

	useEffect(() => {
		if (!filters.length) {
			setIndexList(10);
			setFilteredTable(false);
			return;
		}

		let testeFilters = {};

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			testeFilters[param] = [];
		});

		filters.forEach((ele) => {
			// testeFilters[arguments[ele]]
			const param = Object.getOwnPropertyNames(ele)[0];
			// testeFilters[param].push(ele[param])
			testeFilters[param].push(String(ele[param]).toLowerCase());

			if (String(ele[param]).toLowerCase() == "false") {
				testeFilters[param].push("null");
			}
		});

		let arrayRetorno = [freteiro.data];

		Object.keys(testeFilters).forEach((key, index) => {
			arrayRetorno.push(
				filtroDinamicoArrays(
					testeFilters,
					key,
					arrayRetorno[arrayRetorno.length - 1]
				)
			);
		});

		const arrayF = removerDadosDuplicados(
			arrayRetorno[arrayRetorno.length - 1],
			"id"
		);
		setFilteredTable(arrayF.reverse());
	}, [filters, freteiro]);

	useEffect(() => {
		const refTable = tableRef.current;
		const scrollHandler = (e) => {
			const tbodyHeight = tbodyRef.current.clientHeight;
			const divTableHeight = e.target.clientHeight;
			const scrollPosition = e.target.scrollTop;
			if (scrollPosition >= tbodyHeight - divTableHeight) {
				setTimeout(() => {
					setIndexList(indexList + 10);
				}, 1000);
			}
		};

		refTable.addEventListener("scroll", scrollHandler, false);

		return () => refTable.removeEventListener("scroll", scrollHandler, false);
	});

	const onClickRemoveFilter = (e) => {
		e.preventDefault();
		const value = e.target.parentNode.firstElementChild.innerText;
		const [key, keyValue] = value.split(":");
		setFilters((last) => [
			...last.filter(
				(item) =>
					String(item[key]).replaceAll(" ", "") !== keyValue.replaceAll(" ", "")
			),
		]);
	};

	const [headList, setHead] = useState([]);

	const updateArray = async () => {
		const header = await getTableColumns("CaminhoesFreteiros");
		setHead([
			"index",
			'admin',
			...Object.keys(header.data).filter((key) => !["empresas_id", 'password', 'created_at', 'updated_at'].includes(key)),
		]);
		getAllData("caminhoes/freteiro", {
			id_filial: user.tipo == "empresa" ? null : user.id_filial,
		})
			.then((response) => {
				dispatch(updateFreteirosList(response.data));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		updateArray();
	}, []);

	const [filtersNew, setFiltersNew] = useState({});

	return (
		<Fragment>
			<div className="section-filters-table mb-5">
				<div className="card-header">
					<h3>Filtros Selecionados</h3>
				</div>
				<Row className="mt-2 row-filters">
					<Col>
						<div>
							{filters.map((item, key) =>
								Object.keys(item).map((key, index) => {
									return (
										<span className="filters-card" key={index}>
											<span className="filters">
												<span className="title">
													{key}: {item[key]}
												</span>
												<i
													className="bi bi-x-lg"
													onClick={onClickRemoveFilter}
												></i>
											</span>
										</span>
									);
								})
							)}
						</div>
					</Col>
				</Row>
			</div>
			<div className="section-filters-table">
				<div className="card-header pb-2">
					<Row className="justify-content-between">
						<Col sm="12" md="6" lg="8">
							<h3 style={{ display: "flex", alignItems: "center" }}>
								Tabela de Freteiros{" "}
								{loading ? (
									<Spinner
										style={{ marginLeft: "10px", fontSize: "1rem" }}
										animation="border"
										size="sm"
									/>
								) : (
									""
								)}
							</h3>
						</Col>
						<Col sm="12" md="6" lg="2">
							<Button
								variant="primary"
								onClick={() => setLancarModal({ flag: true })}
							>
								+Adicionar
							</Button>
						</Col>
					</Row>
				</div>
				<div className=" responsive-table" ref={tableRef}>
					<ScrollbarLeft
						tableRef={tableRef}
						coluna2={3}
						maxima_distancia={180}
					/>
					<Table hover size="sm">
						<thead style={{ zIndex: 49 }}>
							<HeadTable
								rota="caminhoes"
								arrayValues={freteiro.data}
								headerList={headList}
								setArrayFilters={setFiltersNew}
								arrayFilters={filtersNew}
								setFiltersOld={setFilters}
								filtersOld={filters}
							/>
						</thead>
						<tbody ref={tbodyRef}>
							{filteredTable
								? filteredTable.map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowFrete
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													setEditarAbastecimento={setEditarAbastecimento}
													tableLength={self.length}
												/>
											);
										}
										// return item
								  })
								: [...freteiro.data].reverse().map((item, index, self) => {
										if (index < indexList) {
											return (
												<BodyRowFrete
													key={index}
													bodyList={item}
													headList={headList}
													index={index}
													setEditarAbastecimento={setEditarAbastecimento}
													tableLength={self.length}
												/>
											);
										}
										// return item
								  })}
						</tbody>
					</Table>
				</div>
			</div>
			{lancarModal.flag ? (
				<ModalCriarFreteiro
					show={lancarModal.flag}
					setShow={setLancarModal}
					title={"Lançar Novo Freteiro"}
				/>
			) : (
				""
			)}
			{editarAbastecimento.flag ? (
				<ModalLancarAbastecimento
					show={editarAbastecimento.flag}
					setShow={setEditarAbastecimento}
					title={editarAbastecimento.title}
					editar={true}
					body={editarAbastecimento.body}
				/>
			) : (
				""
			)}
		</Fragment>
	);
}
