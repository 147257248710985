import { Fragment, useRef, useState } from "react"
import { Card, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"

import './BodyRow.scss'
import { valorMoeda, locationDev, correctDataUTC, deleteData } from "../../../services/Servicos"
import ModalEditarRowFrete from "../../modal/ModalEditarRowFrete"

export default function BodyRowFrete({ headList, bodyList, index, setEditarAbastecimento = () => {}, tableLength, restartData}) {

   const refRow = useRef(null)
    const refEditar = useRef(null)
    const user = useSelector(state => state.userSlice)
    const filiaisList = user.filiaisList.data 
    const [loading, setLoading] = useState(false)

    const [editarRow, setEditarRow] = useState({
        flag: false,
    })
    const onClickEditarRow = (e) => {
        setEditarRow({
            show: true,
            body: bodyList,
        })
    }

    const onClickTableRow = (e) => {
        e.preventDefault()
        refRow.current.classList = 'bg-selected'
        let refRowNumber = index

        let i = refRowNumber
        let actualSibling = refRow.current.nextSibling

        while(i < tableLength){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            
            actualSibling = actualSibling.nextSibling
            i++
        }

        i =  index
        actualSibling = refRow.current.previousSibling
        while(0 < i ){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            actualSibling = actualSibling.previousSibling
            i--
        }     
        
        
    }


    return (
        <Fragment>
            {
                <tr ref={refRow}  onClick={onClickTableRow}>
                    {
                        headList.map((item, _index) => {

                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            }                                
                            else if (item === 'admin') {

                                return <td key={_index}>
                                    {
                                        <div className="admin">
                                            <span className="editar"><i className="bi bi-gear" title="Editar" onClick={onClickEditarRow} ref={refEditar}></i></span>
                                        </div>
                                    }
                                </td>
                            } else if (item === 'id') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            } else if (item === 'placa') { /* primary key can not be modified */

                                return <td key={_index}>{bodyList[item]}</td>

                            } 
                            else if (item === 'status') {

                                return bodyList[item] ?
                                    <td key={_index} className={item}>
                                        <div className="div-status">
                                            <span className="div-status-ativo">Ativo</span>
                                        </div>
                                    </td> : <td key={_index} className={item}>
                                        <div className="div-status">
                                            <span className="div-status-inativo">Inativo</span>
                                        </div>
                                    </td>

                            } 
                            else if (item.includes('valor')) { /* primary key can not be modified */

                                return <td key={_index} style={{minWidth:'10rem'}}>{valorMoeda(bodyList[item])}</td>

                            }
                            else if (item === 'data') { /* primary key can not be modified */

                                return <td key={_index} style={{minWidth:'10rem'}}>{bodyList[item]}</td>

                            }
                            else if (item === 'litros_abastecidos' || item === 'ultimo_km') { /* primary key can not be modified */

                                return <td key={_index} className={item}> {Number(bodyList[item]).toLocaleString('pt-br')}</td>

                            }
                            else if (item === 'emissao') { /* primary key can not be modified */

                                return <td key={_index} className={item}> {correctDataUTC(bodyList.emissao)}</td>

                            }
                            else if (item === 'id_filial') {
                                return <td key={_index}>{filiaisList.filter(item => item.idFilial == bodyList.id_filial)[0]?.identificacaoInterna ?? 'NÃO INFORMADO'}</td>
                            } 
                            return <td key={_index} className={item}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
            
			{editarRow.show ? (
				<ModalEditarRowFrete
					setShow={setEditarRow}
					{...editarRow}
				/>
			) : (
				""
			)}
        </Fragment>
    )
}