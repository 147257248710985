import { Component, createRef, useContext, useEffect, useRef, useState } from "react";
import { Alert, Fade } from "react-bootstrap";
import { removeMessageListUUID } from "../../redux/slicers/user";
import { useDispatch } from "react-redux";
import { getStatusCarga } from "../../services/Servicos";
const alertRef = createRef(null)

export default function AlertModal({message}) {
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const [count, setSeconds] = useState(10)
  
  const removeFromList = () => {
    setShow(false)
    setTimeout(()=>{
      dispatch(removeMessageListUUID(message.uuid))

    }, 500)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if(count <= 0){
        clearInterval(interval)
        removeFromList()
      }else{
        setSeconds(last => last - 1)
      }
    }, 1000)
    return () => clearInterval(interval)
  })
  

  return (
    <Alert className={`alert alert-warning d-flex justify-content-between ${show ? '' : 'hidden-notification'}`} onClose={removeFromList} dismissible>
      {/* <pre>
        {JSON.stringify(message, null, 2)}
      </pre> */}
      <div className="d-flex flex-column">
        <Alert.Heading>{message.tipo}</Alert.Heading>
        <p>
          {message.message} <b>{message.statusPedido ? getStatusCarga(message.statusPedido) : ''}</b>
        </p>
      </div>
      <span className="countdown-timer-alert"/>
    </Alert>
  )
}