import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalAtualizarCargas from "../../modal/ModalAtualizarCargas";
import ModalListarProdutos from "../../modal/ModalListarProdutos";
import ModalListarRomaneios from "../../modal/ModalListarRomaneios";

import "./BodyRow.scss";
// import PedidosVendas from "../../planoLogistica/apiVST/PedidosVstFromCargas"
import ModalAddPedidos from "../../modal/ModalAddPedidos";
import {
	getAllData,
	getStatusCarga,
	locationDev,
	numerosEditadoBR,
} from "../../../services/Servicos";
import ModalErrorTotal from "../../modal/ModalErrorTotal";
// import ModalAtualizarDimensoes from "../../modal/ModalAtualizarDimensoes"
import ModalAtualizarCarga from "../../modal/ModalAtualizarCarga";
import { updateProdutosList } from "../../../redux/slicers/user";
import ModalVisualizarPDFComputer from "../../modal/ModalVisualizarPDFComputer";
import ModalControleManifesto from "../../modal/ModalControleManifesto";
import ModalListarPedidosTransferencia from "../../modal/ModalListarPedidosTransferencia";
import ModalAddPedidosTransferenciaACarga from "../../modal/ModalAddPedidosTransferenciaACarga";
import { getColorOfFilial, getColorOfManifestoStatus, getErrorMessage, getFilialName, isSuporte, returnDataWithCorrection } from "../../../services/helpers";
import ModalListarEmbalagens from "../../modal/ModalListarEmbalagens";
import { Button, Modal, Spinner } from "react-bootstrap";
import ModalErrorPretty from "../../modal/ModalErrorPretty";
import ModalVisualizarManifesto from "../../modal/ModalVisualizarManifesto";

export default function BodyRowCargas({
	headList,
	bodyList,
	index,
	tableLength,
	setCargaSelecionada = () => {},
	setArrayFilters = () => {},
}) {
	const refRow = useRef(null);
	const refEditar = useRef(null);
	const refExcluir = useRef(null);
	const rotasSelect = useSelector((state) => state.userSlice.rotasList.data);
	const caminhoesFrete = useSelector((state) => state.userSlice.freteiroList.data);
	const cidades = (rota) =>
		rotasSelect
			.filter((item) => item.rota === rota && item.step != 1)
			.map((item) => item.cidade)
			.filter((i, n, s) => s.indexOf(i) === n)
			.map((item) => ({
				desccidade: item.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
			}));
	const dispatch = useDispatch();

	const user = useSelector((state) => state.userSlice);
	const funcionarios = user.funcionariosList.data;
	const filiaisList = user.filiaisList.data;

	const [error, setError] = useState({show: false, message: ''});
	
	const [loading, setLoading] = useState(false);

	const [loadingManifesto, setLoadingManifesto] = useState(false);

	const [adminFunction, setAdminFunction] = useState({
		error: {
			flag: false,
			title: "",
			msg: "",
			confirmar: "",
		},
	});

	const [listarInformacoes, setListarInformacoes] = useState({ show: false });

	const [caminhaoJaAtribuido, setCaminhaoJaAtribuido] = useState({
		flag: false,
	});

	const [romaneiosModal, setRomaneiosModal] = useState({
		flag: false,
		cargas_id: "",
	});

	const [produtosModal, setProdutosModal] = useState({
		flag: false,
		cargas_id: "",
	});

	const [embalagensModal, setEmbalagensModal] = useState({
		flag: false,
		cargas_id: "",
	});

	const [addPedidos, setAddPedidos] = useState({
		flag: false,
		cargas_id: "",
		cidades: [],
		id_filial: ``,
	});

	const [addPedidosTransferencias, setAddPedidosTransferencias] = useState({
		flag: false,
		cargas_id: "",
		cidades: [],
		id_filial: ``,
	});

	const [listarTransferencias, setListarTransferencias] = useState({
		flag: false,
		cargas_id: "",
	});

	const [editarDataSaida, setEditarDataSaida] = useState({
		show: false,
	});

	const [verPDF, setVerPDF] = useState({
		show: false,
		PDF: "",
		title: "",
	});

	const onClickEditarRow = async (e) => {
		e.preventDefault();
		const body = {...bodyList}
		
		delete body?.data_saida
		delete body?.data_chegada
		delete body?.created_at
		delete body?.updated_at
		delete body?.createdAt
		delete body?.updatedAt

		dispatch(updateProdutosList([]));
		setAdminFunction({
			error: {
				flag: true,
				title: "Editar Carga",
				msg: {
					title: `Deseja Editar a Carga:`,
					msg: {
						...refRow.current.children,
						pk: body.id,
						produtoMaiorComp: ``,
						status: body.status,
					},
					body: body,
				},
				confirmar: "Atualizar",
			},
		});
	};

	const onClickEditarDataSaida = (e, carga) => {
		setEditarDataSaida({
			show: true,
			parametro: "data_saida",
			current_value: carga.data_saida,
			cargas_id: carga.id,
		});
	};

	const onClickExcluirRow = (e) => {
		e.preventDefault();

		setAdminFunction({
			error: {
				flag: true,
				title: "Excluir Carga",
				msg: {
					title: `Deseja excluir a carga:`,
					// msg: `Tipo: ${ref1}; Nome: ${ref2}; CPF: ${ref3}; ID: ${ref4}`
					msg: {
						id: bodyList.id,
						nome: bodyList.nome,
						volume: bodyList.volume_total,
						peso: bodyList.peso_total,
						qtde_pedidos: bodyList.qtde_pedidos,
						qtde_produtos: bodyList.qtde_produtos,
						observacao: bodyList.observacao,
						status: bodyList.status,
					},
					body: bodyList,
				},
				confirmar: "Excluir",
			},
		});
	};

	const onClickTableRow = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		let refRowNumber = index;

		let i = refRowNumber;
		let actualSibling = refRow.current.nextSibling;

		while (i < tableLength) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";

			actualSibling = actualSibling.nextSibling;
			i++;
		}

		i = index;
		actualSibling = refRow.current.previousSibling;
		while (0 < i) {
			if (actualSibling === null) {
				break;
			}
			actualSibling.classList = "";
			actualSibling = actualSibling.previousSibling;
			i--;
		}
		// const id = Number(refRow.current.children[2].innerText)
		// const nome = refRow.current.children[3].innerText
		// const disponivel = refRow.current.children[9].innerText.toUpperCase() === 'INDISPONIVEL' ? true : false
		// const caminhao = refRow.current.children[12].innerText.toUpperCase()
		// const caminhoneiro = refRow.current.children[13]?.innerText.toUpperCase()
		// setCargaSelecionada({id, nome, disponivel, caminhao, caminhoneiro})
		setCargaSelecionada({
			...bodyList,
			disponivel: bodyList.status === 0 ? true : false,
		});
	};

	const onClickVerPedidos = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		// let refRowNumber = refRow.current.children[2].innerText

		setRomaneiosModal({
			flag: true,
			cargas_id: Number(bodyList.id),
		});
	};

	const onClickVerProdutos = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		// let refRowNumber = refRow.current.children[2].innerText
		setProdutosModal({
			flag: true,
			cargas_id: Number(bodyList.id),
		});
	};

	const onClickVerEmbalagens = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";
		// let refRowNumber = refRow.current.children[2].innerText
		setEmbalagensModal({
			show: true,
			cargas_id: Number(bodyList.id),
			title: "Emabalagens da Carga",
		});
	};

	const onIncluirPedidos = (e) => {
		if (bodyList.caminhao && bodyList.tipo_transporte !== "FRETE") {
			setCaminhaoJaAtribuido({ flag: true });
			return;
		}
		setAddPedidos((last) => ({
			...last,
			flag: true,
			cargas_id: bodyList.id,
			cidades: cidades(bodyList.rota),
			id_filial: bodyList.id_filial,
		}));
	};

	const onIncluirPedidosTransferencias = (e) => {
		if (
			bodyList.caminhao &&
			bodyList.tipo_carga !== "TRANSFERÊNCIA" &&
			bodyList.tipo_transporte !== "FRETE"
		) {
			setCaminhaoJaAtribuido({ flag: true });
			return;
		}
		setAddPedidosTransferencias((last) => ({
			...last,
			flag: true,
			cargas_id: bodyList.id,
			cidades: cidades(bodyList.rota),
			id_filial: bodyList.id_filial,
		}));
	};

	const onClickVerPedidosTransferencias = (e) => {
		e.preventDefault();
		refRow.current.classList = "bg-selected";

		setListarTransferencias({
			flag: true,
			cargas_id: Number(bodyList.id),
		});
	};

	const [controleManifesto, setControleManifesto] = useState({
		flag: false,
		body: {},
	});

	const onClickVerificarPeso = async () => {
		setLoading(true);
		try {
			const data = await getAllData(
				`cargas/verifica-peso?cargas_id=${bodyList.id}`
			);
			setListarInformacoes({ show: true, info: data.data, carga: bodyList.id });
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const handleClickManifesto = async () => {

		if (user.tipo != 'empresa' && !isSuporte(user.email)) return setError({show: true, message: "Area de Manifesto não habilitada na sua conta."})

			setLoadingManifesto(true)
		
		let body = {...bodyList}
		try {
			const {data} = await getAllData(`manifesto/v2/consulta-by-carga?cargas_id=${bodyList.id}`)
			body.chave = data.chave
		} catch (error) {
			
		}
		setControleManifesto({ flag: true, body: body })
		setLoadingManifesto(false)
	}
	const [loadingStatusManifesto, setLoadingStatusManifesto] = useState({
		location: "pdf",
		flag: false,
	});
	const [manifestoDeCargaFile, setManifestoDeCargaFile] = useState({
		show: false,
		PDF: "",
	});

	const verPDfHandler = async (e) => {
		setLoadingStatusManifesto({ location: "pdf", flag: true });
		try {
			const manifesto = await getAllData(`manifesto/v2/get-pdf?chave=${bodyList.manifesto}`, )
			
			setManifestoDeCargaFile({
				show: true,
				PDF: manifesto.data.data.data,
			});
		} catch (error) {
			setError({ show: true, message: getErrorMessage(error)});
		} finally {
			setLoadingStatusManifesto({ location: "pdf", flag: false });
			
		}
	};

	return (
		<Fragment>
			{
				<tr ref={refRow} onClick={onClickTableRow}>
					{headList.map((item, _index) => {
						if (item === "index") {
							return <td key={_index}> {index + 1}</td>;
						} else if (item === "suporte") {
							return (
								<td key={_index}>
									<div className="admin align-items-baseline">
										<Fragment>
											<Fragment>
												<span className="excluir">
													<i
														className="bi bi-x-lg"
														title="Excluir"
														onClick={onClickExcluirRow}
														ref={refExcluir}
													></i>
												</span>
												<span className="editar">
													<i
														className="bi bi-pencil"
														title="Editar"
														onClick={onClickEditarRow}
														ref={refEditar}
													></i>
												</span>
												{!loading ? (
													<span>
														<i
															className="bi bi-search"
															title="Verificar Peso"
															onClick={() =>
																onClickVerificarPeso(bodyList.carga)
															}
															ref={refEditar}
														></i>
													</span>
												) : (
													""
												)}
												{loading ? (
													<Spinner size="sm" animation="border" />
												) : (
													""
												)}
											</Fragment>
										</Fragment>
									</div>
								</td>
							);
						} else if (item === "admin") {
							return (
								<td key={_index}>
									<div className="admin">
										{user.tipo.toLowerCase() === "empresa" ||
										user.tipo.toLowerCase() === "gestor" ? (
											<Fragment>
												{bodyList.status < 12 ? (
													<Fragment>
														<span className="excluir">
															<i
																className="bi bi-x-lg"
																title="Excluir"
																onClick={onClickExcluirRow}
																ref={refExcluir}
															></i>
														</span>
														<span className="editar">
															<i
																className="bi bi-pencil"
																title="Editar"
																onClick={onClickEditarRow}
																ref={refEditar}
															></i>
														</span>
													</Fragment>
												) : (
													<span className="">
														<i
															className="bi bi-bookmark-check-fill"
															title="Finalizado"
															style={{ color: "green" }}
														></i>
													</span>
												)}
											</Fragment>
										) : (
											<span className="editar">
												<i
													className="bi bi-bookmark-check-fill"
													title="Editar"
												></i>
											</span>
										)}
										{user.tipo.toLowerCase() === "empresa" ||
										user.tipo.toLowerCase() === "gestor" ? (
											<span
												className="editar"
												style={{ position: "relative" }}
												title="Manifesto"
												onClick={handleClickManifesto}
											>
													{loadingManifesto ? <Spinner size='sm' color="black" animation="border"/> : <i
														className="bi bi-card-checklist"
														style={{ position: "absolute" }}
													></i>
													}
											</span>
										) : (
											""
										)}
									</div>
								</td>
							);
						} else if (item === "transferencias") {
							return (
								<td key={_index}>
									<div className="admin">
										{user.tipo.toLowerCase() === "empresa" ||
										user.tipo.toLowerCase() === "gestor" ? (
											<Fragment>
												{bodyList.status < 8 ? (
													<span className="verificar">
														<i
															className="bi bi-plus-circle"
															title="Incluir Transferências"
															onClick={onIncluirPedidosTransferencias}
														></i>
													</span>
												) : (
													""
												)}
											</Fragment>
										) : (
											""
										)}
										<span className="verificar">
											<i
												className="bi bi-question-circle"
												title="Ver Transferências"
												onClick={onClickVerPedidosTransferencias}
											></i>
										</span>
										<span className="verificar">
											{bodyList.transferencias ?? 0}
										</span>
									</div>
								</td>
							);
						} else if (item === "qtde_pedidos") {
							return (
								<td key={_index}>
									<div className="admin">
										{user.tipo.toLowerCase() === "empresa" ||
										user.tipo.toLowerCase() === "gestor" ? (
											<Fragment>
												{bodyList.status < 8 ? (
													<span className="verificar">
														<i
															className="bi bi-plus-circle"
															title="Incluir Pedidos"
															onClick={onIncluirPedidos}
														></i>
													</span>
												) : (
													""
												)}
											</Fragment>
										) : (
											""
										)}
										<span className="verificar">
											<i
												className="bi bi-question-circle"
												title="Ver Pedidos"
												onClick={onClickVerPedidos}
											></i>
										</span>
										<span className="verificar">{bodyList.qtde_pedidos}</span>
									</div>
								</td>
							);
						} else if (item === "qtde_produtos") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="verificar">
											<i
												className="bi bi-question-circle"
												title="Ver Pedidos"
												onClick={onClickVerProdutos}
											></i>
										</span>
										<span className="verificar">{bodyList.qtde_produtos}</span>
									</div>
								</td>
							);
						} else if (item === "embalagens") {
							return (
								<td key={_index}>
									<div className="admin">
										<span className="verificar">
											<i
												className="bi bi-question-circle"
												title="Ver Pedidos"
												onClick={onClickVerEmbalagens}
											></i>
										</span>
										<span className="verificar">
											{bodyList.embalagens ?? 0}
										</span>
									</div>
								</td>
							);
						} else if (item === "emissao") {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{bodyList.emissao
										? returnDataWithCorrection(
												bodyList.emissao
										  ).toLocaleDateString("pt-br", { timeZone: "UTC" })
										: "N/A"}
								</td>
							);
						} else if (item === "updated_at") {
							// return <td key={_index} className='rowDataWidth'> {bodyList.updated_at}</td>
						} else if (item === "status") {
							return (
								<td
									key={_index}
									className="rowDataWidth-cargas"
									name={Number(bodyList.status)}
								>
									<div
										style={{
											whiteSpace: "nowrap",
											display: "flex",
											alignItems: "center",
										}}
									>
										{/* {bodyList.status >=24 ? <span className="">
                                            <i className="bi bi-shop" title="Finalizado" style={{ color: 'green' }}></i>
                                        </span> : ""} */}
										{bodyList.status >= 13 && bodyList.status <= 24 ? (
											<span className="">
												<i
													className="bi bi-bookmark-check-fill"
													title="Finalizado"
													style={{ color: "green" }}
												></i>
											</span>
										) : (
											""
										)}
										{bodyList.status < 13 && bodyList.status > 9 ? (
											<span className="">
												<i
													className="bi bi-truck"
													title="Finalizado"
													style={{ color: "black" }}
												></i>
											</span>
										) : (
											""
										)}
										{bodyList.status <= 9 && bodyList.status >= 0 ? (
											<span className="">
												<i
													className="bi bi-boxes"
													title="Finalizado"
													style={{ color: "black" }}
												></i>
											</span>
										) : (
											""
										)}
										{bodyList.status == -2 ? (
											<div className="div-status">
												<span className="div-status-manutencao">
													{getStatusCarga(Number(bodyList.status))}
												</span>
											</div>
										) : (
											""
										)}
										{bodyList.status == -3 ? (
											<div className="div-status">
												<span
													className="div-status-inativo"
													style={{ backgroundColor: "#ff495acf" }}
												>
													{getStatusCarga(Number(bodyList.status))}
												</span>
											</div>
										) : (
											""
										)}
										{bodyList.status == -4 ? (
											<div className="div-status">
												<span
													className="div-status-inativo"
													style={{ backgroundColor: "#49a5ffcf" }}
												>
													{getStatusCarga(Number(bodyList.status))}
												</span>
											</div>
										) : (
											""
										)}
										{bodyList.status > -2
											? getStatusCarga(Number(bodyList.status))
											: ""}
									</div>
								</td>
							);
						} else if (item === "nome") {
							return (
								<td
									key={_index}
									style={{ whiteSpace: "nowrap" }}
									name={Number(bodyList.status)}
								>
									{" "}
									{bodyList[item]}
								</td>
							);
						} else if (item === "data_saida") {
							return (
								<td key={_index} style={{ whiteSpace: "nowrap" }}>
									<div className="">
										{bodyList.status < 6 &&
										(user.tipo.toLowerCase() == "empresa" ||
											user.tipo.toLowerCase() == "gestor") ? (
											<span className="verificar">
												{" "}
												<i
													className="bi bi-calendar-date"
													title="Editar Data"
													onClick={(e) => onClickEditarDataSaida(e, bodyList)}
													style={{ cursor: "pointer" }}
												></i>
											</span>
										) : (
											""
										)}

										<span className="">
											{bodyList.data_saida
												? `${bodyList.data_saida}`
												: "Não Especificado"}
										</span>
									</div>
								</td>
							);
							// return <td key={_index} className='rowDataWidth' name={getStatusCarga(Number(bodyList.status))}></td>
						} else if (item === "created_at") {
							return (
								<td key={_index} className="rowDataWidth">
									{bodyList.created_at}
								</td>
							);
						} else if ( item === "peso_total" || item === "volume_total" || item === "metros_quadrados") {
							return (
								<td key={_index} className={item}>
									{numerosEditadoBR(bodyList[item])}
								</td>
							);
						} else if (item === "liberacao_financeiro_file") {
							return (
								<td key={_index} className={item}>
									{!bodyList[item] ? (
										"Sem Arquvio"
									) : (
										<i
											className="bi bi-file-earmark-pdf"
											onClick={(e) => {
												setVerPDF({
													show: true,
													PDF:
														locationDev +
														"/relatorio-financeiro/" +
														bodyList[item],
													title: "PDF de Liberação",
												});
											}}
											style={{ cursor: "pointer" }}
										>
											Relatório
										</i>
									)}
								</td>
							);
						} else if (item === "fechamento_financeiro_file") {
							return (
								<td key={_index} className={item}>
									{!bodyList[item] ? (
										"Sem Arquvio"
									) : (
										<i
											className="bi bi-file-earmark-pdf"
											onClick={(e) => {
												setVerPDF({
													show: true,
													PDF:
														locationDev +
														"/relatorio-financeiro/" +
														bodyList[item],
													title: "PDF de Fechamento",
												});
											}}
											style={{ cursor: "pointer" }}
										>
											Relatório
										</i>
									)}
								</td>
							);
						} else if (item === "manifesto") {
							return (
								<td key={_index} className={item}>
									{!bodyList[item] ? (
										"Sem Manifesto"
									) : (
										loadingStatusManifesto.flag ? (
											<Spinner animation="border" size="sm" />
										) : (
										<div onClick={verPDfHandler} style={{ cursor: "pointer" }}> 
											<i className="bi bi-file-earmark-pdf"></i>
											Manifesto
										</div>
										)
									)}
								</td>
							);
						} else if (item === "caminhoneiro") {
							return (
								<td key={_index}>
									{bodyList.tipo_transporte == 'FRETE' ? caminhoesFrete.find(i => i.placa == bodyList.caminhao)?.motorista_nome ?? 'N/A'
									: (funcionarios.find(
										(item) => item.cpf == bodyList.caminhoneiro
									)?.name ?? "N/A")}
								</td>
							);
						} else if (item === "id_filial") {
							return (
								<td key={_index}>
									<div style={{
										color: getColorOfFilial(bodyList.id_filial).color,
										backgroundColor: getColorOfFilial(bodyList.id_filial).bgColor,
										padding: '4px 6px',
										borderRadius: '4px',
										textAlign: 'center',
										boxShadow: '0 0 5px #0A061E80',
										border: '1px solid #E2E3DF80',
										width:'max-content'

									}}>
										{
											getFilialName(filiaisList, bodyList.id_filial)
										}
									</div>
								</td>
							);
						} else if (item.includes("data")) {
							return (
								<td key={_index} className="rowDataWidth">
									{" "}
									{bodyList[item]
										? returnDataWithCorrection(
											bodyList[item]
										  ).toLocaleString("pt-br", { timeZone: "UTC" })
										: "N/A"}
								</td>
							);
						} else if (item === 'status_manifesto') {
							return <td key={_index}>
								<div style={{
									color: getColorOfManifestoStatus(bodyList.status_manifesto ?? '').color,
									backgroundColor: getColorOfManifestoStatus(bodyList.status_manifesto ?? '').bgColor,
									padding: '4px 6px',
									borderRadius: '4px',
									textAlign: 'center',
									boxShadow: '0 0 5px #0A061E80',
									border: '1px solid #E2E3DF80',
									width:'max-content'

								}}>
									{bodyList.status_manifesto ?? 'N/A' }
								</div>
							   </td> 
						} 
						return (
							<td key={_index} className={item}>
								{" "}
								{bodyList[item]}
							</td>
						);
					})}
				</tr>
			}
			{editarDataSaida.show ? (
				<ModalAtualizarCarga
					title={"Editar Data de Saida"}
					show={editarDataSaida.show}
					setShow={setEditarDataSaida}
					{...editarDataSaida}
				/>
			) : (
				""
			)}
			{adminFunction.error.flag ? (
				<ModalAtualizarCargas
					title={adminFunction.error.title}
					msg={adminFunction.error.msg}
					show={adminFunction}
					setShow={setAdminFunction}
					msgButton={adminFunction.error.confirmar}
				/>
			) : (
				""
			)}
			{romaneiosModal.flag ? (
				<ModalListarRomaneios
					msg={""}
					title={"Romaneios da Carga"}
					show={romaneiosModal}
					setShow={setRomaneiosModal}
					carga={romaneiosModal.cargas_id}
				/>
			) : (
				""
			)}
			{produtosModal.flag ? (
				<ModalListarProdutos
					msg={""}
					title={"Produtos da Carga"}
					show={produtosModal}
					setShow={setProdutosModal}
					pedido={produtosModal}
				/>
			) : (
				""
			)}
			{embalagensModal.show ? (
				<ModalListarEmbalagens
					msg={""}
					setShow={setEmbalagensModal}
					{...embalagensModal}
				/>
			) : (
				""
			)}
			{cidades.length && addPedidos.flag ? (
				<ModalAddPedidos
					setShow={setAddPedidos}
					show={addPedidos.flag}
					title={"Adicionar Venda a carga"}
					cargas_id={addPedidos.cargas_id}
					cidades={addPedidos.cidades}
					id_filial={addPedidos.id_filial}
				/>
			) : (
				""
			)}
			{caminhaoJaAtribuido.flag ? (
				<ModalErrorTotal
					title={"Atenção"}
					msg={
						"Não é possível colocar mais produtos para uma carga atrelada a um caminhao!"
					}
					show={caminhaoJaAtribuido.flag}
					setShow={setCaminhaoJaAtribuido}
				/>
			) : (
				""
			)}
			{verPDF.show ? (
				<ModalVisualizarPDFComputer
					title={verPDF.title}
					pdf={verPDF.PDF}
					setShow={setVerPDF}
					show={verPDF.show}
				/>
			) : (
				""
			)}
			{controleManifesto.flag ? (
				<ModalControleManifesto
					show={controleManifesto.flag}
					setShow={setControleManifesto}
					title={`Controle de Manifesto`}
					body={controleManifesto.body}
				/>
			) : (
				""
			)}
			{addPedidosTransferencias.flag ? (
				<ModalAddPedidosTransferenciaACarga
					setShow={setAddPedidosTransferencias}
					show={addPedidosTransferencias.flag}
					title={"Adicionar Transferência a carga"}
					cargas_id={addPedidosTransferencias.cargas_id}
				/>
			) : (
				""
			)}
			{listarTransferencias.flag ? (
				<ModalListarPedidosTransferencia
					setShow={setListarTransferencias}
					show={listarTransferencias.flag}
					title={"Adicionar Transferência a carga"}
					cargas_id={listarTransferencias.cargas_id}
				/>
			) : (
				""
			)}
			{listarInformacoes.show ? (
				<ModalListarInfoCarga
					setShow={setListarInformacoes}
					{...listarInformacoes}
				/>
			) : (
				""
			)}
			{manifestoDeCargaFile.show ? (
				<ModalVisualizarManifesto
					title={"Manifesto de Carga"}
					pdf={manifestoDeCargaFile.PDF}
					setShow={setManifestoDeCargaFile}
					show={manifestoDeCargaFile.show}
				/>
			) : (
				""
			)}
			{error.show ? <ModalErrorPretty setShow={setError} {...error} />:""}
		</Fragment>
	);
}

function ModalListarInfoCarga({ show, setShow, info, carga }) {
	const handlerSimpleClose = () => {
		setShow((last) => ({ error: { flag: false } }));
	};

	const [btn, setBtn] = useState({ message: "Atualizar", style: "primary" });
	const onClickAtualizar = async () => {
		setBtn({ message: "Atualizando", style: "warning" });
		try {
			const data = await getAllData(
				`cargas/verifica-peso?cargas_id=${carga}&updateTotal=true`
			);
			setBtn({ message: "Atualizado", style: "success" });
		} catch (error) {
		} finally {
			setBtn({ message: "Atualizar", style: "primary" });
		}
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title> Informações da carga </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<pre>{JSON.stringify(info, null, 2)}</pre>
				</Modal.Body>
				<Modal.Footer className="modal-footer-no-border">
					<Button variant={btn.style} onClick={onClickAtualizar}>
						{btn.message}
						{btn.style == "success" ? (
							<i className="bi bi-check-circle"></i>
						) : (
							""
						)}
						{btn.style == "warning" ? (
							<Spinner size="sm" animation="border" />
						) : (
							""
						)}
					</Button>
					<Button variant="secondary" onClick={handlerSimpleClose}>
						Sair
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
