import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { QRCodeSVG } from "qrcode.react";

import "./Modal.scss";
import {
	apenasNumeros,
	getCorretData,
	getStatusPedidos,
	numeroDecimais,
	numerosDecimal,
	numerosEditadoBR,
	updateTableNew,
	valorMoeda,
} from "../../services/Servicos";
import { getErrorMessage, telefoneMask } from "../../services/helpers";
import ModalErrorPretty from "./ModalErrorPretty";
import BotaoSalvar from "../botoes/BotaoSalvar";

export default function ModalEditarRowFrete(props) {
	const user = useSelector((state) => state.userSlice);
	const handlerSimpleClose = () => props.setShow((last) => ({ show: false }));

	const [body, setBody] = useState(props.body);
	const [btn, setBtn] = useState({
		flag: false,
		style: "primary",
		msg: "Salvar",
	});
	
	const [error, setError] = useState({show: false, message: ''})
	const [validaEmail, setValidaEmail] = useState(false)

	const submit = async () =>  {
		setBtn({
			flag: true,
			msg: "Carregando",
			style: "warning",
		});
		try {
			if(validaEmail) throw new Error('Email não válido.')
			await updateTableNew('caminhoes/freteiro', {...body, placa: props.body.placa})
			setBtn({
				flag: true,
				msg: "Concluido",
				style: "success",
			});
			setTimeout(()=>{
				handlerSimpleClose()
			}, 1000)
		} catch (error) {
			setError({
				show: true,
				message: getErrorMessage(error)
			});
			setBtn({
				flag: false,
				msg: "Salvar",
				style: "primary",
			});
		} 
	}

	const onChangeInput = (e) => {
			if (e.target.name === "email") {
				if(e.target.value.includes('.frete@fermat.com')){
					setValidaEmail(false)
				}else{
					setValidaEmail(true)
				}
				setBody((last) => ({
					...last,
					[e.target.name]: e.target.value,
				}));
				return;
			}
			setBody((last) => ({
				...last,
				[e.target.name]: e.target.value.toUpperCase(),
			}));
		};
	return (
		<>
			<Modal
				show={props.show}
				onHide={handlerSimpleClose}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>Acesso ao aplicativo</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Email:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="email"
									name="email"
									placeholder="Email deve ter '.frete@fermat.com'"
									onChange={onChangeInput}
									value={body?.email}
								/>
								{validaEmail ? <div className="text-danger">O email de acesso deve possuir <i style={{fontSize: 'inherit', fontWeight: 'bold'}}>.frete@fermat.com</i></div>: ''}
							</Col>
						</Row>
						<Row className="mb-2">
							<Col sm="4">
								<Form.Label>Senha:</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									name="password"
									placeholder="Email deve ter '.frete@fermat.com'"
									onChange={onChangeInput}
									value={body?.password}
								/>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<div style={{ minWidth: "150px" }}>
						<BotaoSalvar
							msg={btn.msg}
							style={btn.style}
							fnRetorno={submit}
						/>
					</div>
					<div style={{ minWidth: "150px" }}>
						<Button variant="secondary" onClick={handlerSimpleClose}>
							Fechar
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
			{error.show ? <ModalErrorPretty {...error} setShow={setError}/> : ""}
		</>
	);
}
