import { Fragment, useRef, useState } from "react"
// import ModalTableProdutos from "../../modal/ModalTableProdutos"

import './BodyRow.scss'
import { getDanfe, getDanfeVST, getStatusPedidos, getXMLString, valorMoeda } from "../../../services/Servicos"
import ModalTableProdutosVendas from "../../modal/ModalTableProdutosVendas"
import { useSelector } from "react-redux"
import ModalVisualizarPDFComputer from "../../modal/ModalVisualizarPDFComputer"
import { Spinner } from "react-bootstrap"
import ModalError from "../../modal/ModalError" 
import ModalVisualizarDanfe from "../../modal/ModalVisualizarDanfe"
import { getColorOfFilial, getFilialName, isSuporte } from "../../../services/helpers"

export default function BodyRowPedidosVendas({ headList, bodyList, index, tableLength, cargas_id, setArrayFilters = () => {}, setPedidos= () => {}   }) {

    const refRow = useRef(null)
    const refEditar = useRef(null)
    const [loading, setLoading] = useState(false)
    const user = useSelector(state => state.userSlice)
    const filiaisList = user.filiaisList.data 
    

    const [produtos, setProdutos] = useState({
        flag:'',
        ID_EMPRESA:'',
        ID_FILIAL:'',
        ID_PEDIDO:'',
    })

    const onClickVerMais = (e) => {
        e.preventDefault()
        const date = new Date(bodyList.dtemissao).toLocaleDateString('pt-BR',{timeZone:'UTC'})
    
        setProdutos(last => ({
            ...last
            , flag:true
            , ID_EMPRESA: bodyList.idEmpresa
            , ID_FILIAL: bodyList.idFilial
            , ID_PEDIDO: bodyList.codigoPedido
            , pedido:{
                  id_filial: bodyList.idFilial
                , numero_pedido: bodyList.codigoPedido
                , emissao : date
                , nome_razao: bodyList.nomeRazao
                , nome_fantasia: bodyList.apelidoFantasia
                , cpf_cnpj: bodyList.cpfCnpj
                , endereco: bodyList.endereco
                , cep: bodyList.cep
                , bairro: bodyList.descbairro
                , cidade: bodyList.desccidade
                , numero: bodyList.numero
                , tel_1: bodyList.fonecelular
                , tel_2: bodyList.foneresidencial
                , valor_total: bodyList.vlrVenda
                , chave_nfe: bodyList.nfeChavedeacesso
                , nome_vendedor: bodyList.nomefunc
                , cargas_id
                , peso_nf: bodyList.pesoBruto
                , responsavel: String(user.name).toUpperCase()
                , cpf_responsavel: String(user.cpf).toUpperCase()
            }
        }))
    
        
    }

    const onClickTableRow = (e) => {
        e.preventDefault()
        refRow.current.classList = 'bg-selected'
        let refRowNumber = refRow.current.children[0].innerText

        let i = refRowNumber
        let actualSibling = refRow.current.nextSibling

        while(i < tableLength){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            
            actualSibling = actualSibling.nextSibling
            i++
        }

        i =  refRow.current.children[0].innerText
        actualSibling = refRow.current.previousSibling
        while(0 < i ){
            if(actualSibling === null){
                break
            }
            actualSibling.classList = ''
            actualSibling = actualSibling.previousSibling
            i--
        }     
        
        // const id = Number(refRow.current.children[2].innerText)
        // const nome = refRow.current.children[3].innerText
        // const disponivel = refRow.current.children[9].innerText.toUpperCase() === 'INDISPONIVEL' ? true : false

        // setCargaSelecionada({id, nome, disponivel})
        
    }

    
    const [verPDF, setVerPDF] = useState({
        show: false,
        PDF: '',
        title:""
    }) 
    const [error, setError] = useState({
        flag: false
    })
    
    const findDanfe = async (e, bodyList_) => {
        setLoading(true)

        const { codigoPedido, idFilial, apelidoFantasia } = bodyList_

        setVerPDF({
            show: true,
            PDF: await getDanfeVST(idFilial, codigoPedido),
            title:'Danfe'
        })

        setError(last => ({
            ...last
            , numero_pedido: ""
        }))
        setLoading(false)


    }

    
    return (
        <Fragment>
            {
                <tr ref={refRow}  onClick={onClickTableRow}>
                    {
                        headList.map((item, _index) => {
                            if (item === 'index') {
                                return <td key={_index}> {index + 1}</td>

                            } 
                            else if (item.toLowerCase() === 'admin') {
                                return <td key={_index}>
                                    <div className="admin" style={{alignItems:'baseline'}}>
                                        {
                                            isSuporte(user.email) ?
                                            <span className="editar"><i className="bi bi-plus-circle" title="Ver Produtos" onClick={onClickVerMais} ref={refEditar}></i></span>
                                            : ""
                                        }
                                        {
                                            ['-1', '24'].includes(bodyList.stLogistica) ? 
                                            <span className="editar"><i className="bi bi-plus-circle" title="Ver Produtos" onClick={onClickVerMais} ref={refEditar}></i></span>
                                            :
                                            <span title="Pedido atrelado a uma carga"><i className="bi bi-bookmark-check-fill" style={{color:'green'}}></i></span>
                                        }
                                        {
                                            loading ? <Spinner animation="border" size="sm"/> : 
                                            <i className="bi bi-file-earmark-pdf" onClick={e => {findDanfe(e, bodyList)}} style={{cursor:'pointer'}}></i>
                                        }
                                    </div>
                                </td>
                            }
                            else if (item === 'vlrVenda') {
                                return <td key={_index} className='rowDataWidth'>{valorMoeda(bodyList.vlrVenda)}</td>
                                
                            }
                            else if (item === 'stLogistica') {
                                return <td key={_index} className='rowDataWidth'>{getStatusPedidos(bodyList.stLogistica)}</td>
                            } 
                                
                            else if (item === 'idFilial') {
                                return (
                                    <td key={_index}>
                                        <div style={{
                                            color: getColorOfFilial(bodyList.idFilial).color,
                                            backgroundColor: getColorOfFilial(bodyList.idFilial).bgColor,
                                            padding: '4px 6px',
                                            borderRadius: '4px',
                                            textAlign: 'center',
                                            boxShadow: '0 0 5px #0A061E80',
                                            border: '1px solid #E2E3DF80',
                                            width:'max-content'
    
                                        }}>
                                            {
                                                getFilialName(filiaisList, bodyList.idFilial)
                                            }
                                        </div>
                                    </td>
                                );
                            } 
                                
                            
                            
                            else if (item.includes('dt')) {

                                return bodyList[item] ? <td key={_index}> {new Date(bodyList[item]).toLocaleDateString('pt-BR',{timeZone:'UTC'})}</td> : <td key={_index}></td>
                            }
                            return <td key={_index} className={item} style={{minWidth:'100px'}}> {bodyList[item]}</td>
                        })
                    }
                </tr>
            }
            {
                produtos.flag? <ModalTableProdutosVendas 
                show={produtos.flag} 
                setShow={setProdutos}
                ID_EMPRESA={produtos.ID_EMPRESA}
                ID_FILIAL={produtos.ID_FILIAL}
                ID_PEDIDO={produtos.ID_PEDIDO}
                stLogistica={bodyList.stLogistica}
                pedido={produtos.pedido}
                setPedidos={setPedidos}
                /> : ""}
            
            {
                verPDF.show ? <ModalVisualizarDanfe title={verPDF.title} pdf={verPDF.PDF} setShow={setVerPDF} show={verPDF.show} /> : ""
            }
            {
                error.flag ? <ModalError title={error.title} msg={error.msg} setShow={setError} show={error.flag}/> : ""
            }
        </Fragment>
    )
}